import { render, staticRenderFns } from "./DischargeDetailComponent.vue?vue&type=template&id=264ce0b1&scoped=true"
import script from "./DischargeDetailComponent.js?vue&type=script&lang=js&external"
export * from "./DischargeDetailComponent.js?vue&type=script&lang=js&external"
import style0 from "./DischargeDetailComponent.vue?vue&type=style&index=0&id=264ce0b1&prod&lang=css"
import style1 from "./DischargeDetailComponent.vue?vue&type=style&index=1&id=264ce0b1&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "264ce0b1",
  null
  
)

export default component.exports