import i18n from "@/i18n";
import { CellType } from '@colven/common-domain-lib/lib';
export const DISCHARGE_MAP_POINT_JSON_TEMPALTE = {
    id: null,
    lat: null,
    lng: null,
    value: "TRUCK",
    filters: [],
    marker: {
        title: "",
        type: "img",
        ref: "truck-icon.svg",
        size: 40,
        tooltipDirection: "right",
        tooltipPermanent: false,
        tooltipBorderColor: "rgb(0,176,240)",
        businessData: {
            number: 0,
            direction: "northeast",
            stopped: false
        }
    }
};

export const DISCHARGE_MAP_JSON_TEMPLATE = {
    name: i18n.t("dischargeSummary.map.name"),
    groups: [{
        name: i18n.t("dischargeSummary.map.groupName"),
        title: i18n.t("dischargeSummary.map.groupTitle"),
        measure: undefined,
        referenceByInterval: false,
        series: [{
            type: "MARKERS",
            points: []
        }],
        references: [],
        exclusive: true,
        displayOnStart: true
    }],
    filters: [],
    info: {}
};

export const IDLING_MAP_JSON_TEMPLATE = {
    name: i18n.t("dischargeSummary.map.nameIdle"),
    groups: [{
        name: i18n.t("dischargeSummary.map.groupNameIdle"),
        title: i18n.t("dischargeSummary.map.groupTitleIdle"),
        measure: undefined,
        referenceByInterval: false,
        series: [{
            type: "MARKERS",
            points: [],
        }],
        references: [],
        exclusive: true,
        displayOnStart: true,
    }],
    filters: [],
    info: {},
};

export const TABLE_HEADERS_DISCHARGE = [{
    "text": "table.headers.discharge.nameThing",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "thingName",
    "width": 220
},
{
    "text": "table.headers.discharge.serviceType",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "serviceType",
    "width": 220
},
{
    "text": "table.headers.discharge.nameDriver",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "nameDriver",
    "width": 220
},
{
    "text": "table.headers.discharge.positionTimestamp",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "cellType": CellType.textBackgroundColor,
    "cellConfig": {
        "round": false,
        "outlined": true,
        "color": "positionTimestampColor",
    },
    "value": "positionTimestamp",
    "width": 220
},
{
    "text": "table.headers.discharge.communicationTimestamp",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "cellType": CellType.textBackgroundColor,
    "cellConfig": {
        "round": false,
        "outlined": true,
        "color": "communicationTimestampColor",
    },
    "value": "communicationTimestamp",
    "width": 220
},
{
    "text": "table.headers.discharge.dischargesAmount",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "dischargesAmount",
    "width": 220
},
{
    "text": "table.headers.discharge.totalLiters",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "totalLiters",
    "width": 220
}

];

export const TABLE_HEADERS_IDLING = [{
    "text": "table.headers.discharge.nameThing",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "thingName",
    "width": 220
},
{
    "text": "table.headers.discharge.serviceType",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "serviceType",
    "width": 220
},
{
    "text": "table.headers.discharge.nameDriver",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "nameDriver",
    "width": 220
},
{
    "text": "table.headers.discharge.positionTimestamp",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "cellType": CellType.textBackgroundColor,
    "cellConfig": {
        "round": false,
        "outlined": true,
        "color": "positionTimestampColor",
    },
    "value": "positionTimestamp",
    "width": 220
},
{
    "text": "table.headers.discharge.communicationTimestamp",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "cellType": CellType.textBackgroundColor,
    "cellConfig": {
        "round": false,
        "outlined": true,
        "color": "communicationTimestampColor",
    },
    "value": "communicationTimestamp",
    "width": 220
},
{
    "text": "table.headers.discharge.idlingDischargesAmount",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "dischargesAmount",
    "width": 220
},
{
    "text": "table.headers.discharge.totalIdlingLiters",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "totalLiters",
    "width": 220
},
{
    "text": "table.headers.discharge.duration",
    "align": "left",
    "filterType": "TEXT_FIELD",
    "selected": true,
    "sortable": true,
    "type": "STRING",
    "value": "totalIdlingTime",
    "width": 220
}

];

export const TABLE_DATA_DISCHARGE_TEMPLATE = {
    thingId: null,
    thingName: null,
    serviceTypeKey: null,
    serviceType: null,
    nameDriver: null,
    positionTimestamp: null,
    communicationTimestamp: null,
    dischargesAmount: null,
    totalLiters: null,
    positionTimestampColor: null,
    communicationTimestampColor: null
};

export const DISCHARGE_MAP_REFERENCE_TEMPLATE = {
    "name": null,
    "color": "rgb(0,176,240)",
    "isInterval": false,
    "title": null
};

export const DISCHARGE_CHAT_JSON_TEMPLATE = {
    type: 'bar',
    data: {
        labels: [],
        datasets: []
    },
    options: {
        responsive: true,
        maintainAspectRatio: false,
        legend: false,
        scales: {
            xAxes: [{
                stacked: true,
                "ticks": {
                    "beginAtZero": true
                },
            }],
            yAxes: [{
                stacked: true,
                "ticks": {
                    "beginAtZero": true
                },
            }]
        },
        tooltips: {
            callbacks: {
                label: (data) => {
                    return `${i18n.t('dischargeSummary.chart.dataset.label')}: ${data.value}`
                }
            }
        },
        "plugins": {
            "zoom": {
                "pan": {
                    "enabled": true,
                    "mode": "xy"
                },
                "zoom": {
                    "enabled": true,
                    "mode": "xy"
                }
            }
        }
    }
};

export const DISCHARGE_CHART_DATASET_TEMPLATE = {
    label: 'dischargeSummary.chart.dataset.label',
    data: [],
    backgroundColor: '#D6E9C6',
    type: undefined,
    dataProperty: undefined,
    originalData: [],
    formattedTooltipData: {
        label: []
    },
    formattedLabelData: [],
    fill: true,
    yAxisID: undefined,
    lineTension: 0
};

export const IDLING_CHART_DATASET_TEMPLATE = {
    label: "dischargeSummary.chart.dataset.label",
    data: [],
    backgroundColor: "#D6E9C6",
    type: undefined,
    dataProperty: undefined,
    originalData: [],
    formattedTooltipData: {
        label: []
    },
    formattedLabelData: [],
    fill: true,
    yAxisID: undefined,
    lineTension: 0
};