import { configurationService } from "@/business/configurationService";
import { reportsColors } from "@/constants/constants";
import { CHART_DATASET_TEMPLATE, CHART_TEMPLATE, MAP_POINT_TEMPLATE, MAP_TEMPLATE, TABLE_DATA_TEMPLATE, TABLE_HEADERS_DISCHARGE, TABLE_HEADERS_IDLING } from "@/constants/discharge-detail.constant";
import i18n from "@/i18n";
import store from "@/store/store";
import { filterRulesMapped } from "@/tools/filterRules";
import { getGoogleMapsURL, speedToStringTranslated, parseTimestamp } from "@/tools/functions";
import { ReportConversionUtil, unitParser, ValueText } from '@colven/common-domain-lib/lib';
import { clone } from "ramda";
/**
 * Obtener los datos para el detalle
 * @param {*} data
 * @param {*} thing
 * @returns
 */
const getDetail = async (data, thing, isDischarges) => {
	return await processData(data, thing, isDischarges);
};

/**
 * Procesa los datos para el componente
 * @param {*} data
 * @param {*} thing
 * @returns
 */
const processData = async (data, thing, isDischarges) => {
	const internationalization = store.getters['user/getInternationalization'];
	const internationalizationType = internationalization.unit;
	const internationalizationTime = internationalization.date;
	const internationalizationDate = internationalization.dateMask;
	// data = groupFieldByDistance(data);
	const result = {
		charts: [],
		maps: [],
		table: {
			itemKey: "numericId",
			headers: [],
			filterRules: {},
			data: [],
		},
		initPosition: {
			lat: 0,
			long: 0,
			zoom: 2.5,
		},
	};
	const mapConfigKey = isDischarges ? "dischargeDetails.map" : "idlingDetails.map";
	const mapConfig = await configurationService.get(mapConfigKey);
	if (mapConfig) {
		result.initPosition.currentLayer = mapConfig.data.currentLayer;
	}

	const chart = clone(CHART_TEMPLATE);
	chart.options.scales.yAxes.forEach(element => {
		element.scaleLabel.labelString = unitParser.changeUnit(element.scaleLabel.labelString, unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) })
	});
	// chart.options.tooltips.callbacks.label = (data) => {
	// 	let label = isDischarges ? i18n.t('dischargeSummary.chart.dataset.label') : i18n.t('dischargeSummary.chart.dataset.labelIdling')
	// 	return `${label}: ${data.value} ${unitParser.getUnit(store.getters['user/getInternationalization'].unit, unitParser.UNITS_NAMES.VOLUME)}`
	// }
	chart.id = "dischargeDetailChart" + (Math.random() + 1).toString(36).substring(7);
	const chartDataset = clone(CHART_DATASET_TEMPLATE);
	chartDataset.label = unitParser.changeUnit(chartDataset.label, unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) })
	const map = clone(MAP_TEMPLATE);
	map.id = Math.random() + 1;

	if (!isDischarges) {
		chart.name = i18n.t("dischargeDetail.chart.nameIdling");
		chart.options.scales.yAxes[0].scaleLabel.labelString = i18n.t("dischargeDetail.chart.yLabelIdling");
		// chart.options.tooltips.callbacks = null;
		chartDataset.label = i18n.t("dischargeDetail.chart.yLabelIdling");
		map.name = i18n.t("dischargeDetail.map.nameIdling");
		map.groups[0].name = i18n.t("dischargeDetail.map.groupNameIdling");
		map.groups[0].title = i18n.t("dischargeDetail.map.groupNameIdling");
	}

	data.forEach((element, index) => {
		// TABLA
		const newElement = clone(TABLE_DATA_TEMPLATE);

		// ID numérico para identificar a la fila
		newElement.numericId = index + 1;

		// nombre del equipo
		newElement.nameThing = thing.name;

		// tipo de servicio
		newElement.serviceType = thing.serviceType;

		// fecha y hora de posición (evento)
		console.log(unitParser.parseDateAndTime(element.trackTimestampEnd, internationalizationDate, internationalizationTime))
		newElement.positionTimestamp = new ValueText(
			element.trackTimestampEnd,
			parseTimestamp(element.trackTimestampEnd)
		);

		// fecha y hora de comunicación
		newElement.communicationTimestamp = new ValueText(
			element.comunicationTimestampEnd,
			parseTimestamp(element.comunicationTimestampEnd)
		);

		// cantidad descargada (litros)
		const totalDischarged = element.totalDischarged || element.totalIdling;
		const totalDischargedNumber = Number(totalDischarged);
		newElement.dischargeAmount = new ValueText(
			unitParser.parseVolumen(totalDischargedNumber, internationalizationType, false),
			unitParser.parseVolumen(totalDischargedNumber, internationalizationType, true)
		);

		//referencia correspondiente en el mapa
		newElement.referenceName = getReference(totalDischargedNumber);

		// odómetro
		if (element.odometerEnd != null && element.odometerEnd != '0') {
			const odometer = Number(element.odometerEnd).toFixed(2);
			newElement.odometer = new ValueText(unitParser.parseDistance(odometer, internationalizationType, false), unitParser.parseDistance(odometer, internationalizationType, true));
		}

		if (element.hourmeterEnd) {
			const hourmeter = Number(element.hourmeterEnd).toFixed(2);
			newElement.hourmeter = new ValueText(hourmeter, `${hourmeter} s`);
		}

		// estado del motor (encendido - apagado)
		if (element.engineRunningEnd != null) {
			const engineStateTranslation = i18n.t(
				`table.data.engineState.${element.engineRunningEnd ? "ON" : "OFF"}`
			);
			newElement.engineState = new ValueText(
				element.engineRunningEnd,
				engineStateTranslation
			);
			newElement.engineStateColor = element.engineRunningEnd
				? "#4CAF50"
				: "#263238";
		}

		// velocidad -- solo para descargas (VC-202)
		if (isDischarges && element.speedEnd != null) {
			newElement.speed = new ValueText(
				element.speedEnd,
				speedToStringTranslated(element.speedEnd, element.directionEnd)
			);
		}

		const globalVolume = Number(element.fuelLevelEnd);
		// volumen global después de la descarga
		newElement.globalVolume = new ValueText(unitParser.parseVolumen(globalVolume, internationalizationType, false), unitParser.parseVolumen(globalVolume, internationalizationType, true));

		let chartToolTip = null;
		// tanque -- solo para descargas (VC-202)
		if (isDischarges) {
			chartToolTip = [
				i18n.t(`dischargeDetail.chart.yLabel`) + ": " + unitParser.parseVolumen(element.totalDischarged, internationalizationType, true)
			];
			let tableDischargedTanks = '';
			//ESTO ES PARA AGREGARLE AL TOOLTIP LA DESCARGA POR TANQUE, queda comentado por ahora.
			/*for (const tankElement in element.tanksDischarged) {
				if (element.tanksDischarged[tankElement]) {
					const label = i18n.t(`table.data.tank.${tankElement}`) + ": " + unitParser.parseVolumen(element.tanksDischarged[tankElement], internationalizationType, true);
					tableDischargedTanks += label + "\n";
					chartToolTip.push(label);
				}
			}*/
			newElement.dischargedTank = tableDischargedTanks;
		}

		// dirección (georefernce)
		newElement.geoReferenceEnd = element.geoReferenceEnd;

		// latitud y longitud
		newElement.lat = element.latEnd;
		newElement.lng = element.longEnd;

		//tiempo en que estuvo en ralentí
		if (element.duration) {
			newElement.idlingTime = ReportConversionUtil.secondsToString(element.duration);
		}

		result.table.data.push(newElement);
		// GRÁFICO
		chartDataset.data.push(parseFloat(newElement.dischargeAmount.value));
		chart.data.labels.push(newElement.positionTimestamp.text);
		chartDataset.formattedTooltipData.label.push(chartToolTip);
		let color;
		if (isDischarges) {
			color = (index % 2 !== 0) ? reportsColors.DISCHARGES_EVEN : reportsColors.DISCHARGES_ODD;
		} else {
			color = (index % 2 !== 0) ? reportsColors.IDLING_EVEN : reportsColors.IDLING_ODD;
		}
		chartDataset.backgroundColor.push(color);

		//<script> document.querySelector(\".filterMapPopup\").onclick = ()=>{console.log(filterMap);filterMap(marker.businessData.number)}; document.querySelector(\".googleLink\").onclick = function(){window.open(\"https://www.google.com/maps/search/?api=1&query=${discharge.latEnd}%2C${discharge.longEnd}\")};</script>
		const litersDischargedTranslation = isDischarges ? "dischargeDetail.map.popup.litersDischarged" : "dischargeDetail.map.popup.litersConsumed";
		// MAPA
		const popup = `
        <h4>${newElement.nameThing}</h4>
        <hr>${unitParser.changeUnit(i18n.t(litersDischargedTranslation), unitParser.UNITS_NAMES.VOLUME, store.getters['user/getInternationalization'].unit, function (text) { return i18n.t(text) })}: ${newElement.dischargeAmount.text
			}<br>
        ${i18n.t("dischargeDetail.map.popup.dateTime")}: ${newElement.positionTimestamp.text
			}<br>
			${i18n.t("dischargeDetail.map.popup.georeference")}: ${newElement.geoReferenceEnd
			}<br>
        <a href="https://www.google.com/maps/search/?api=1&query=${element.latEnd}%2C${element.longEnd}"
        class="googleLink" target="_blank">${i18n.t(
				"dischargeDetail.seeOnGoogle"
			)}</a>`;
		const point = clone(MAP_POINT_TEMPLATE);
		point.id = newElement.numericId;
		point.lat = element.latEnd;
		point.lng = element.longEnd;
		point.value = totalDischarged;
		point.marker.label = `<span style="width:100%;height:100%;">${newElement.nameThing} - ${newElement.dischargeAmount.text} - ${newElement.positionTimestamp.text}</span>`;
		point.marker.businessData = {
			numericId: newElement.numericId,
			hasLinks: true,
			popup
		};
		map.groups[0].series[0].points.push(point);
		result.initPosition.lat = element.latEnd;
		result.initPosition.long = element.longEnd;
	});

	result.table.headers = isDischarges ? TABLE_HEADERS_DISCHARGE : TABLE_HEADERS_IDLING;
	result.table.headers.forEach(header => {
		result.table.filterRules[header.value] =
			filterRulesMapped[header.filterType];
	});
	if (!isDischarges) {
		result.table.headers = result.table.headers.filter((header) => { return header.value !== 'engineState' });
	}
	chart.data.datasets.push(chartDataset);
	result.charts = [chart];
	result.maps = [map];

	return result;
};

/**
 * Obtener la URL para ir a ver un punto en Google Maps
 * @param {*} lat
 * @param {*} long
 * @returns
 */
// eslint-disable-next-line no-unused-vars
const goToGoogleMaps = (lat, long) => {
	const url = getGoogleMapsURL(lat, long);
	window.open(url);
};

/**
 * Obtener el modelo para el componente
 * @param {*} dischargeData
 * @returns
 */
const getComponentModel = (dischargeData = null) => {
	if (dischargeData != null) {
		return {
			data: dischargeData.discharges ? dischargeData.discharges : [],
			thing: {
				id: dischargeData.thingId,
				name: dischargeData.thingName,
				serviceTypeKey: dischargeData.serviceTypeKey,
				serviceType: dischargeData.serviceType
			}
		};
	} else {
		return {
			data: [],
			thing: {
				id: null,
				name: null,
				serviceTypeKey: null,
				serviceType: null
			}
		};
	}
};

/**
 * Cargar el breadcrumb con el selector de equipos
 * @param {*} data
 * @param {*} selectedItem
 */
const loadBreadcrumbSelector = (data, selectedItem) => {
	store.dispatch("breadcrumb/setEntitySelectorConfiguration", {
		name: i18n.t("equipments"),
		value: "thingId",
		text: "thingName"
	});
	const items = data.map(element => ({
		thingId: element.thingId,
		thingName: element.thingName
	}));
	store.dispatch("breadcrumb/setEntitySelectorItems", items);
	const item = {
		thingId: selectedItem.thingId,
		thingName: selectedItem.thingName
	};
	store.dispatch("breadcrumb/setSelectedItem", item);
};

/**
 * Obtener la referencia correspondiente del mapa según los litros
 */
function getReference(total) {
	if (total < 20) {
		return "10-discharge";
	}
	if (total >= 20 && total < 40) {
		return "20-discharge";
	}
	if (total >= 40 && total < 60) {
		return "30-discharge";
	}
	if (total >= 60 && total < 80) {
		return "40-discharge";
	}
	if (total >= 80) {
		return "50-discharge";
	}
	return ''
}

export default {
	getDetail,
	getComponentModel,
	goToGoogleMaps,
	loadBreadcrumbSelector
};
