import dischargeDetailService from "@/business/dischargeDetail.service";
import { configurationService } from "@/business/configurationService";
import { SnackbarStyle } from "@/constants/constants";
import { mapActions } from "vuex";
import i18n from "@/i18n";

export default {
  components: {},
  model: {
    prop: "model",
    event: "change"
  },
  props: {
    model: {
      type: Object,
      required: true
    },
    // clave del reporte para guardar y obtener la configuración
    reportKey: {
      type: String,
      required: true
    },
    isDischarges: {
      type: Boolean,
      required: true
    }
  },
  data: () => ({
    // boolean para deshabilitar el botón de refresh de la tabla
    disableRefreshTable: true,
    // loading
    loading: false,
    // configuración de la tabla
    tableConfig: {},
    // fila resaltada
    highlightRow: null,
    // botones de cada fila
    rowActions: [
      {
        id: 1,
        name: i18n.t("dischargeDetail.seeOnGoogle"),
        color: "red",
        icon: "mdi-google-maps"
      }
    ],
    // gráficos
    charts: [],
    // mapas
    maps: [],
    // tabla
    tableItemKey: "numericId",
    tableHeaders: [],
    tableFilterRules: {},
    tableData: [],
    reportDetails: '',
    initPosition: null,
    dataPositionAndZoomChanged: [],
    pointsToShow: [],
    selectedReferences: []
  }),
  async mounted() {
    await this.getConfig();
    // cargar datos en pantalla
    if (this.model.data) {
      this.updateReport(this.model.data);
    }
  },
  methods: {
    ...mapActions({
      showSnackbar: "snackbar/showSnackbar"
    }),
    /**
     * Método que recibe los datos de la fila clickeada, obtiene el nro de fila que la identifica y llama a filtrar las cargas en el mapa.
     * @param {*} rowData
     */
    filterDischargeByRowClick(rowData) {
      this.filterDischarge(rowData.numericId, rowData.referenceName);
      this.highlightSelectedRow(rowData.numericId);
      this.centerMap(rowData);
    },
    /**
     * En base al número de fila que identifica los datos del equipo se filtran los markers del mapa y se actualizan las referencias seleccionadas de los filtros
     * (donde los markers de las cargas tienen ese mismo número de propiedad en el businessData)
     * @param {*} numericId
     * @param {*} refname
     */
    async filterDischarge(numericId, refname) {
      const updatedPoints = this.maps[0].groups[0].series[0].points.filter(
        (dischargePoint) => {
          return dischargePoint.marker.businessData.numericId === numericId;
        }
      );
      if (updatedPoints.length > 0) {
        updatedPoints[0].groupIndex = 0;
      }
      const groupRef = [
        {
          name: this.maps[0].groups[0].name,
          refs: [refname],
          points: updatedPoints
        },
      ];
      this.selectedReferences.cleanAndUpdate(groupRef);
    },
    /**
     * Método para resaltar una fila en la tabla
     * @param {*} param0 
     */
    highlightSelectedRow(numericId) {
      if (numericId != null) {
        this.highlightRow = null;
        const that = this;
        this.$nextTick(() => {
          that.highlightRow = numericId;
        });
      }
    },
    /**
     * TODO
     * revisar si se debe implementar la acción refrescar en el detalle
     */
    refreshTable() {
      console.log("refresh table");
    },
    /**
     * Para cargar la configuración de la tabla
     */
    async getConfig() {
      const config = await configurationService.get(this.reportKey);
      this.tableConfig = config && config.data ? config.data : {};
    },
    /**
     * Para guardar la configuración de la tabla
     * @param {*} config
     */
    saveConfig(config) {
      configurationService
        .save(this.reportKey, config)
        .then(() => {
          this.showSnackbar({
            visible: true,
            text: this.$t("user.configuration.saveSuccess"),
            timeout: 10000,
            style: SnackbarStyle.SUCCESS
          });
        })
        .catch(() => {
          this.showSnackbar({
            visible: true,
            text: this.$t("user.configuration.saveError"),
            timeout: 10000,
            style: SnackbarStyle.ERROR
          });
        });
    },
    /**
     * cuando se hace click en un marcador del mapa, se resalta la fila en la tabla
     * @param {*} param0
     */
    onMapClick({ numericId }) {
      this.highlightSelectedRow(numericId);
    },
    /**
     * Centrar el mapa luego de hacer click en una fila de la tabla
     * Esto se copió de otros reportes que tienen la misma función
     * TO DO: implementar en el componente MapComponent una prop reactiva o modelo y un watch para ejecutar el centrado y así no usar referencias al DOM físico
     * @param {*} selectedItem
     */
    centerMap({ lat, lng }) {
      const mapRef = this.$refs.dischargeDetailMap;
      if (mapRef != null && lat != null && lng != null) {
        mapRef.centerMap([lat, lng]);
      }
    },
    /**
     * Handler para las acciones de los botones de las filas de la tabla
     * @param {*} button
     * @param {*} data
     */
    rowButtonClicked(button, data) {
      switch (button.id) {
        case 1:
          if (data.lat != null && data.lng != null) {
            dischargeDetailService.goToGoogleMaps(data.lat, data.lng);
          } else {
            this.showSnackbar({
              visible: true,
              text: this.$t("dischargeDetail.goToGoogleError"),
              timeout: 10000,
              style: SnackbarStyle.ERROR
            });
          }
          break;
        default:
          console.error("Undefined button", button);
      }
    },
    /**
     * Cuando cambian los datos de la prop data, se actualizan los datos de la tabla, el mapa y el gráfico
     * @param {*} data
     */
    async updateReport(data) {
      this.loading = true;
      const { charts, maps, table, initPosition } = await dischargeDetailService.getDetail(
        data,
        this.model.thing,
        this.isDischarges
      );
      this.initPosition = initPosition;
      this.charts.cleanAndUpdate(charts);
      this.maps.cleanAndUpdate(maps);
      this.tableItemKey = table.itemKey;
      this.tableData.cleanAndUpdate(table.data);
      this.tableHeaders.cleanAndUpdate(table.headers);
      this.tableFilterRules = table.filterRules;
      this.getConfig();
      this.reportDetails = this.model.reportDetails;
      this.loading = false;
    },
    positionAndZoomChanged(data) {
      this.dataPositionAndZoomChanged = data;
      this.initPosition = { ...this.dataPositionAndZoomChanged };
      const mapConfigKey = this.isDischarges ? "dischargeDetails.map" : "idlingDetails.map";
      configurationService.save(mapConfigKey, { currentLayer: data.currentLayer });
    },
  },
  watch: {
    /**
     * Cuando se modifica un valor del modelo
     */
    model: {
      handler: function (newValue) {
        this.updateReport(newValue.data);
      },
      deep: true
    }
  }
};
